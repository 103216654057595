import { useCallback, useEffect, useRef, useState } from 'react';
import { useIdleTimer } from 'react-idle-timer';
import { useAuth } from 'contexts/authentication';
import { FeatureFlagsService } from 'services/feature-flags/feature-flags';
import { useNavigate } from 'react-router-dom';
import { Routes } from 'common/routes';

const IDLE_TIMEOUT_MINUTES = 30;

export const useIdleTicker = () => {
  const inGame = new URL(window.top.location.href).pathname.includes(
    Routes.Game.path,
  );
  const navigate = useNavigate();
  const { auth } = useAuth();
  const [state, setState] = useState<string>('Active');
  const onIdle = () => {
    setState('Idle');
  };
  const onActive = () => {
    setState('Active');
  };
  useIdleTimer({
    onIdle,
    onActive,
    timeout: 60_000,
    throttle: 500,
  });

  const lastActive = useRef<Date>(new Date());
  const idleTicker = useRef<NodeJS.Timer>();
  const resetTime = useCallback(() => {
    lastActive.current = new Date();
  }, []);

  const addTimeIdle = () => {
    const minutes = Math.floor(
      Math.abs(
        (lastActive.current.getTime() - new Date().getTime()) / 1000 / 60,
      ),
    );
    if (minutes > IDLE_TIMEOUT_MINUTES && !inGame) {
      window.location.href = '/en/logout';
    }
    if (FeatureFlagsService?.featureFlags?.environment !== 'prod') {
      console.log(`user idle timer: ${minutes}min`);
    }
  };
  useEffect(() => {
    if (state === 'Idle' && auth.session?.playerId) {
      resetTime();
      const tickerInterval = 60 * 1000;
      idleTicker.current = setInterval(addTimeIdle, tickerInterval);
    }
    return () => {
      clearInterval(idleTicker.current);
    };
  }, [state, auth.session]);
};
