import { bp } from 'common/theme';
import {
  Bell,
  Promotion,
  HowItWorks,
  LogOut,
  PlayerInfo,
  Profile,
  Cross,
  MakeADeposit,
} from 'icons/svg';
import styled from '@emotion/styled';
import { NavItem } from 'components/organisms/layout/my-account-nav/nav-item';
import React from 'react';
import { Routes } from '../../../../common/routes';
import { Tournament } from '../../../../icons/svg/tournament';
import { useXtremeNotifications } from '../../../../hooks/use-xtreme-notifications/use-xtreme-notifications';
import { useNavigate } from '../../../../hooks/use-navigate';
import { useAuth } from '../../../../contexts/authentication';
import { FeatureFlagsService } from 'services/feature-flags/feature-flags';
import { logout } from '../../../../services/auth/logout';

export type MyAccountNavProps = {
  toggle: () => void;
};

const MyAccountNavDropdown = styled.div`
  height: 100vh;
  background: var(--nav-background);

  min-width: 370px;
  width: 100%;
  box-sizing: border-box;

  @media screen and (max-width: 500px) {
    overflow: auto;
    padding-top: 20px;
    padding-bottom: 100px;
  }

  @media ${bp.sm} {
    padding-top: 102px;
  }

  @media ${bp.lg} {
    padding-top: 0;
  }

  nav {
    list-style: none;
    margin: 0;
    padding: 0;
    overflow: auto;
    height: calc(100% - 60px);

    @media ${bp.lg} {
      height: calc(100% - 109px);
    }
  }

  li {
    border-bottom: 2px solid var(--primary-darker-color);
  }
`;

export const StyledCloseProfileButton = styled.button`
  margin-left: auto;
  height: 24px;
  width: 24px;
  @media screen and (max-width: 500px) {
    height: 36px;
    width: 36px;
  }
  background: var(--primary-color);
  border: unset;
  cursor: pointer;
  border-radius: 99px;
`;

export default function MyAccountNav({ toggle }: MyAccountNavProps) {
  const { auth, signOut } = useAuth();
  const navigate = useNavigate();
  const { data: notifications, refetch: refetchNotifications } =
    useXtremeNotifications();
  const displayInbox = () => {
    toggle();
    if (window['xtremepush'])
      window['xtremepush'](
        'inbox',
        'open',
        {},
        () => {},
        () => {},
      );
    setTimeout(refetchNotifications, 1000);
  };

  return (
    <MyAccountNavDropdown>
      <div
        className='d-flex align-items-center gap-4 py-3 px-2'
        style={{
          borderBottom: '1px solid var(--bg-darkish-color)',
        }}
      >
        <Profile width={44} />
        <div>
          {auth.session?.firstName
            ? `Hi, ${auth.session?.firstName}`
            : 'Welcome'}
        </div>
        <StyledCloseProfileButton
          onClick={toggle}
          className='d-flex justify-content-center align-items-center position-relative'
        >
          <Cross stroke={'#000'} size={'24px'} />
        </StyledCloseProfileButton>
      </div>

      <nav>
        <NavItem
          text='Notifications'
          icon={<Bell width={28} height={28} />}
          notifications={notifications > 0}
          onClick={displayInbox}
        />
        <NavItem
          text='Account Balance'
          icon={<MakeADeposit />}
          withChildren={[
            {
              text: 'Deposit',
              onClick: () => {
                navigate(Routes.MakeDepositPage);
                toggle();
              },
            },
            {
              text: 'Buy Credits',
              onClick: () => {
                navigate(Routes.WagersPage);
                toggle();
              },
            },
            {
              text: 'Withdraw',
              onClick: () => {
                navigate(Routes.WithdrawPage);
                toggle();
              },
            },
            {
              text: 'Account History',
              onClick: () => {
                navigate(Routes.WagerHistory);
                toggle();
              },
            },
          ]}
        />
        <NavItem
          text='Promotions'
          icon={<Promotion width={44} />}
          onClick={() => {
            navigate(Routes.Promotions);
            toggle();
          }}
        />
        <NavItem
          text='Account Settings'
          icon={<PlayerInfo />}
          withChildren={[
            {
              text: 'Personal Details',
              icon: <PlayerInfo />,
              onClick: () => {
                navigate(Routes.PlayerInfo);
                toggle();
              },
            },
            {
              text: 'Communication Preferences',
              icon: <PlayerInfo />,
              onClick: () => {
                navigate(Routes.CommunicationPreferences);
                toggle();
              },
            },
            {
              text: 'Documents Center',
              icon: <PlayerInfo />,
              onClick: () => {
                navigate(Routes.DocumentCenter);
                toggle();
              },
            },
            {
              text: 'Responsible Gaming',
              icon: <PlayerInfo />,
              onClick: () => {
                navigate(Routes.ResponsibleGamingSettings);
                toggle();
              },
            },
            {
              text: 'Change Password',
              icon: <PlayerInfo />,
              onClick: () => {
                navigate(Routes.ChangePassword);
                toggle();
              },
            },
          ]}
        />
        {FeatureFlagsService?.featureFlags?.enableTournaments && (
          <NavItem
            text='Tournaments'
            icon={<Tournament width={44} />}
            onClick={() => {
              navigate(Routes.Tournaments);
              toggle();
            }}
          />
        )}
        <NavItem
          text='Help'
          icon={<HowItWorks />}
          href='https://support.bspot.com/hc/en-us'
          target='_blank'
        />
        <NavItem
          onClick={async () => {
            toggle();
            if (ENABLE_NEXT_AUTH === 'true') {
              window.location.href = '/en/logout';
            } else {
              await logout();
            }
          }}
          text='Log Out'
          icon={<LogOut />}
        />
      </nav>
    </MyAccountNavDropdown>
  );
}
